.App {
  text-align: center;
  background-color: black;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Poppins', sans-serif;
  letter-spacing: 1.5px;
}
